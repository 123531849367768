// styles/index.scss

// Import variables and other SCSS features
@import '_variables';

// Use variables, mixins, placeholders, functions, and other SCSS features as needed

.about {
  @include flex-layout(column, start, center, nowrap); // Using the flex-layout mixin with specified parameters
  background-color: $primary-color;
  padding: 20px;
  text-align:center;

  h2 {
    font-size: 24px;
    font-weight: bold;
    color:$text-color;
  }
    p {
      font-size: 16px;
      line-height: 1.5;
      color:$text-color;

      &:hover {
        color:red;
      }
    }

    a {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        color: darken($primary-color, 10%);
      }
    }
  }

// Other styles...
